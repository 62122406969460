@import "../../common.scss";

.header_sec1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 6.667em;
  max-height: 6.667em;
  z-index: 9;
  padding: 2.556em 0 0;
  background-color: $color-ffffff;
  -webkit-box-shadow: 0px 3px 6px 0.6px $color-00000029;
  -moz-box-shadow: 0px 3px 6px 0.6px $color-00000029;
  box-shadow: 0px 3px 6px 0.6px $color-00000029;

  .navlinks {
    position: relative;
    ul {
      display: flex;
      justify-content: flex-end;
      max-width: 50%;
      float: right;
      .logo_wrapper {
        position: absolute;
        top: -1em;
        left: -1em;
        .logo_img {
          position: relative;
          max-width: 23.292em;
        }
      }
      .nav_tab {
        @include textStyles(1.25em, 1.5em, 0);
        position: relative;
        color: $color-202020;
        text-transform: capitalize;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0 5.8em;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.5em;
          width: 0;
          height: 1.5px;
          background-color: $color-C4A049;
          transition: 0.3s width;
        }
        &:focus::after {
          width: calc(0% + -0.15em);
        }
        .logo_img {
          position: absolute;
          top: -2.8em;
          left: -3em;
          max-width: 7em;
        }
        a {
          font-size: 1.22em;
          color: $color-202020;
          position: relative;
          padding: 2em 0;
        }
        .nolink {
          cursor: default !important;
          pointer-events: none;
        }

        &:hover {
          font-weight: 500;
        }
      }

      .nav_tab:hover::after {
        width: calc(100% + -0.15em);
      }
      .nav_tab.active {
        font-weight: 600;
        &::after {
          width: calc(100% + -0.15em);
        }
      }
    }
  }
}

.down_arrow {
  max-width: 0.55em;
  padding-bottom: 0.25em;
}
@media (max-width: 1500px) {
  .header_sec1 {
    .navlinks {
      .logo_wrapper {
        .logo_img {
          max-width: 20.704em;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .header_sec1 {
    height: auto;
    z-index: 201;
    padding: 2em 0 0;
    .logo_wrapper {
      z-index: 9999;
      .logo_img {
        left: 0;
      }
      .popawala_logo {
        width: 100%;
        margin-left: 1.5em;
        max-width: 16.2em;
      }
    }

    .nav_line2_wrapper_flex_wrapper {
      position: fixed;
      top: 0;
      left: -100%;
      background-color: rgba($color: $color-ffffff, $alpha: 1);
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      transition: 0.3s left;
      &.active {
        left: 0;
      }
      .nav_social_media_icons {
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;

        .nav_social_icons {
          width: 100%;
          max-width: 2em;
          height: 1.5em;
          object-fit: cover;
          margin: 0 0.8em;
        }
        a:last-child {
          .nav_social_icons {
            margin-right: 0;
          }
        }
        .follow_us_text {
          font-size: 1.6em;
          text-align: center;
          margin-top: 1.5em;
          a {
            color: $color-202020;
          }
        }
      }
    }

    .nav_line2_wrapper_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 80%;
      overflow-y: auto;
      width: 100%;
      margin-left: 30%;
      margin-top: 12.1em;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: $color-202020;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-202020;
      }
      .nav_item {
        position: relative;
        cursor: pointer;
        margin-left: 0;
        padding-bottom: 3.3em;
      }
      .nav_link_wrapper {
        position: relative;
        width: fit-content;
        margin: 0 auto;
      }
      .nav_link {
        cursor: pointer;
        color: $color-202020;
        text-transform: capitalize;
        font-size: 2.4em;
        font-weight: 500;
        opacity: 0.9;
        display: block;
        text-align: center;
      }
      .nav_link.active {
        position: relative;
        font-weight: 600;
        color: $color-202020;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   bottom: -0.4em;
        //   left: 0;
        //   background-color: $color-202020;
        //   height: 2.2px;
        //   width: 2em;
        // }
      }
    }

    .hamburger_lines {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5em;
      width: 2.8em;
      height: 2.2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .line {
        height: 2px;
        width: 100%;
        border-radius: 1em;
        background-color: $color-202020;
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      .line1 {
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      .line2 {
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      .line3 {
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }
      &.active {
        .line {
          background-color: $color-202020;
        }
        .line1 {
          top: 18px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: translateY(1.1em) rotate(135deg);
        }

        .line2 {
          opacity: 0;
          left: -60px;
        }

        .line3 {
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: translateY(-1em) rotate(-135deg);
        }
      }
    }
    .navlinks {
      ul {
        .nav_tab {
          margin: 0 0 0 2.722em;
          a {
            font-size: 1em;
          }
        }
      }
    }
  }
}
