@import "../../common.scss";

.contact_sec1 {
  margin: 16.144em 0 9.375em;
  position: relative;
  .left_section {
    .text_container {
      position: relative;
      background-color: $color-ffffff;
      padding: 2em 4.438em 1.25em 4.5em;
      margin-right: 3em;
      z-index: 3;
      -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
      -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
      box-shadow: 0px 1px 6px 0.6px $color-00000029;
      z-index: 2;
      .heading {
        text-align: center;
        @extend %HeadingStyle;
        font-weight: 700;
        margin-bottom: 1em;
        color: $color-000000;
        opacity: 0.8;
      }
      .locationdesc {
        align-items: flex-start !important;
      }
      .icon_desc {
        display: flex;
        align-items: center;
        margin-bottom: 3.5em;
        .phone_icon {
          max-width: 3em;
          padding-top: 0.5em;
        }
        .phone_box {
          margin-left: 1.892em;
          display: flex;
          flex-direction: column;
          .phone_desc {
            @extend %DescStyle;
            a {
              color: $color-202020;
            }
          }
        }
      }
    }
  }
  .right_section {
    iframe {
      margin: -3em 0 0 0;
      z-index: 2;
      max-width: 78.5em;
      max-height: 47.563em;
    }
  }
}

.contact_sec2 {
  margin-bottom: 11.063em;
  .text_container {
    text-align: center;
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      margin: 1.145em 0 3.48em;
    }
  }
  .tabs_content_box {
    .left_section {
      ul {
        padding: 0;
        display: flex;
        justify-content: center;
      }
      .lefttab {
        @include textStyles(1.875em, 1.5em, 0px);
        text-transform: uppercase;
        color: $color-7F8080;
        font-weight: 600;
        // background-color: $color-000000;
        opacity: 0.8;
        cursor: pointer;
        padding: 0;
        margin: 0 2em;
        transition: 0.3s all ease-in-out;
        .pic {
          margin: 1em 0 0; /* demo spacing */
          display: inline-block;
          width: 0;
          height: 0;
        }
      }
      .lefttab.active {
        color: $color-202020;
        // background: url("../../images/aboutus/Group\ 3171@2x.png");
        // background-color: black;
        // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
        background-size: contain;
        font-weight: 600;
        position: relative;
        opacity: 1;
        transition: 0.3s all ease-in-out;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .arrow-up {
          border-left: 0.7em solid transparent;
          border-right: 0.7em solid transparent;
          border-bottom: 0.7em solid $color-C6A14A;
        }
        .arrow_right {
          display: inline;
        }
      }
    }
    .rightbox {
      margin: 0 13.5em;
      padding: 3.5em 3.188em 3.5em 3.25em;
      border-top: 0.45em solid $color-C6A14A;
      -webkit-box-shadow: 0px 3px 6px $color-00000029;
      -moz-box-shadow: 0px 3px 6px $color-00000029;
      box-shadow: 0px 3px 6px $color-00000029;
      z-index: 2;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .form_container {
        max-width: 100%;
        .form_row {
          justify-content: center;
          .message_box {
            input {
              max-width: 97.5%;
            }
          }
        }
        .error {
          position: absolute;
          text-transform: capitalize;
          font-size: 1em;
          font-weight: 500;
          margin: 0.5em 0;
          color: red;
        }
        .material-textfield {
          position: relative;
          margin: 1.438em 0 3.729em;
          background-color: transparent;
          label {
            position: absolute;
            background-color: transparent;
            @include textStyles(1em, 1em, 0px);
            font-weight: 500;
            color: $color-202020;
            left: 0;
            top: 50%;
            padding: 1.5em 0 0em 0.3em;
            margin: 0 0.3em;
            transform: translateY(-100%) scale(1.2);
            pointer-events: none;
            transform-origin: left top;
            transition: 0.1s ease-out;
            opacity: 0.8;
          }
          input {
            @include textStyles(1em, 1em, 0px);
            background-color: transparent;
            padding: 0.5em 0 0.5em 0.3em;
            font-weight: 500;
            color: $color-202020;
            width: 100%;
            max-width: 95%;
            outline: none;
            border: none;
            transition: 0.1s ease-out;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
          }
          input:focus {
            border-color: $color-202020;
            border-bottom: 1px solid rgba(0, 0, 0, 1);
          }
          input:focus + label {
            color: $color-202020;
            top: 0;
            font-size: 0.738em;
            transform: translateY(-90%) scale(1);
            transition: all 0.3s ease-in;
          }
          input:not(:placeholder-shown) + label {
            top: 0;
            transform: translateY(-90%) scale(1);
            font-size: 0.738em;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }

          .mobile_no_flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            .cc_box {
              max-width: 18%;
              font-size: 1.125em;
              letter-spacing: 0.24px;
              font-weight: 500;
              position: relative;
              .cc_dd {
                border: none;
                background-color: $color-ffffff;
                padding: 0 1em 0.4em 0;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
              }
              .dropwdown_icon {
                position: absolute;
                top: 0.6em;
                right: 0;
                max-width: 0.563em;
              }
            }
            .no_box {
              width: 100%;
              max-width: 82%;
              position: relative;
              input {
                position: relative;
                max-width: 100%;
                margin-left: 1em;
                padding: 0.5em 0 0.5em 0.3em;
              }
              label {
                left: 1em;
              }
            }
          }
        }

        .cta_wrapper {
          margin: 1.438em 0 0;
          text-align: right;
          display: flex;
          justify-content: center;
          button {
            @include textStyles(1.125em, 1.7em, 0.18px);
            color: $color-202020;
            margin: 0.1em 0;
            width: 100%;
            max-width: 14.334em;
            height: 3.445em;
            outline: 0;
            border-radius: 2em;
            text-transform: capitalize;
            text-decoration: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: $color-ffffff;
            border: 1px solid $color-BE8E34;
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
            &:hover {
              border: none !important;
              color: $color-ffffff;
              background: linear-gradient(
                90deg,
                rgba(190, 142, 52, 1) 50%,
                rgba(235, 201, 139, 1) 100%
              );
            }
          }
          .send {
            @include textStyles(1.2em, 1em, 0.2px);
            width: 100%;
            max-width: 12.5em;
            height: 3.5em;
            padding: 1em 0em;
            outline: 0;
            background-color: transparent;
            text-transform: capitalize;
            opacity: 0.8;
            text-decoration: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2em;
            color: $color-ffffff;
            background-color: $color-202020;
            transition: all 0.3s ease-in;
            box-shadow: 0 4px 12px var(--shadow);
            transform: translateY(var(--y));
            transition: box-shadow 0.3s ease, transform 0.3s ease;
            &:hover {
              --y: -2px;
              box-shadow: 0 8px 16px var(--shadow);
            }
            &:active {
              --y: 1px;
              box-shadow: 0 4px 8px var(--shadow);
            }
            img {
              margin-left: 1em;
              max-width: 1.8em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1460px) {
  .contact_sec1 {
    .right_section {
      iframe {
        margin: -3em 0 0 0;
        z-index: 2;
        max-width: 78.5em;
        max-height: 49.563em;
      }
    }
  }
}
@media (max-width: 992px) {
  .contact_sec1 {
    margin: 6em 0;
    .left_section {
      margin-bottom: 5em;
      .text_container {
        margin-right: 0;
        .heading {
          font-size: 3.2em;
        }
        .icon_desc {
          .phone_box {
            .phone_desc {
            }
          }
          .phone_icon {
            max-width: 3em;
          }
        }
      }
    }
    .right_section {
      position: relative;
      iframe {
        margin: 0;
        max-width: 100%;
      }
    }
  }

  .contact_sec2 {
    .text_container {
      .desc {
        margin: 1.145em 0 2.48em;
      }
    }
    .tabs_content_box {
      .left_section {
        .lefttab {
          font-size: 2.2em;
        }
        .lefttab.active {
          font-size: 2.2em;
        }
      }
      .rightbox {
        margin: 0 7.5em;
        .form_container {
          .material-textfield {
            margin: 1em 0;
            input {
              font-size: 1.2em;
              border-bottom: 0.1px solid rgba(0, 0, 0, 0.8);
            }
            .mobile_no_flex {
              .no_box {
                padding-right: 15px;
                max-width: 100%;
                input {
                  max-width: 95%;
                  border-bottom: 0.1px solid rgba(0, 0, 0, 0.8);
                }
              }
              .cc_box {
                .cc_dd {
                  border-bottom: 0.1px solid rgba(0, 0, 0, 0.8);
                }
              }
            }
          }
          .cta_wrapper {
            margin: 3em 0 0;
            button {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .contact_sec1 {
    margin: 6em 0;
    .left_section {
      .text_container {
        padding: 3em 2em 1em;
        .heading {
          @include textStyles(2.1em, 1.7em, 0.42px);
        }
        .icon_desc {
          .phone_icon {
            max-width: 2.2em;
          }
          .phone_box {
            .phone_desc {
              letter-spacing: 0.3px !important;
            }
          }
        }
      }
    }
  }

  .contact_sec2 {
    .text_container {
      .desc {
        br {
          display: none;
        }
      }
    }
    .tabs_content_box {
      .rightbox {
        margin: 0;
        padding: 5em 15px;
        .form_container {
          .material-textfield {
            margin: 1em 0 3em;
            input,
            label {
              font-size: 1.4em;
            }
            input {
              max-width: 100%;
            }
            .mobile_no_flex {
              .no_box {
                padding-right: 15px;
                max-width: 100%;
                input {
                  // max-width: 97.5%;
                }
              }
              .cc_box {
                .cc_dd {
                  padding: 0.5em 1em 0.5em 0;
                }
                .dropwdown_icon {
                  top: 1.1em;
                }
              }
            }
          }
          .form_row {
            .message_box {
              input {
                max-width: 100%;
              }
            }
          }
          .cta_wrapper {
            margin: 1em 0 0;
          }
        }
      }
      .left_section {
        .lefttab {
          font-size: 1.8em;
          margin: 0 1em;
        }
        .lefttab.active {
          font-size: 1.8em;
        }
      }
    }
  }
}

//MIXIN here
//MIXIN here
//MIXIN here
//MIXIN here
//MIXIN here
%leftDescStyle {
  @include textStyles(1.25em, 1.7em, 0.5px);
  font-weight: 500;
  color: $color-202020;
  opacity: 1;
  text-transform: initial;
}
