@import "../../common.scss";

.product_sec1 {
  margin: 9.444em 0 9.375em;
  .product_boxes {
    align-items: center;
    .text_container {
      max-width: 85%;
      .breadcrumb_text {
        @extend %BreadCrumb;
        text-transform: inherit;
        margin: 2.5em 0 1.167em;
      }
      .heading {
        @extend %HeadingStyle;
      }
      .desc {
        @extend %DescStyle;
        margin: 1.667em 0 2.778em;
      }
    }
    .image_container {
      overflow: hidden;
      .chemical_img {
        width: 100%;
        transform: scale(1.2);
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}

.product_sec2 {
  margin: 0 0 7.313em;
  .subheading_box {
    text-align: center;
    margin-bottom: 3.75em;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin-bottom: 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
  }
  .left_section {
    ul {
      padding: 0;
      display: flex;
      justify-content: space-around;
    }
    .lefttab {
      @include textStyles(1.875em, 1.5em, 0px);
      text-transform: uppercase;
      color: $color-202020;
      font-weight: 600;
      // background-color: $color-202020;
      opacity: 1;
      cursor: pointer;
      padding: 0;
      margin: 0;
      transition: 0.3s all ease-in-out;
      .pic {
        margin: 0.6em 0 0; /* demo spacing */
        display: inline-block;
        width: 0;
        height: 0;
      }
    }
    .lefttab.active {
      color: $color-058F39;
      // background: url("../../images/aboutus/Group\ 3171@2x.png");
      // background-color: black;
      // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
      background-size: contain;
      font-weight: 600;
      position: relative;
      opacity: 1;
      transition: 0.3s all ease-in-out;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .arrow-up {
        border-left: 0.7em solid transparent;
        border-right: 0.7em solid transparent;
        border-bottom: 0.7em solid $color-C6A14A;
      }
      .arrow_right {
        display: inline;
      }
    }
  }
  .rightbox {
    padding: 3.5em 3.188em 3.5em 3.25em;
    border-top: 0.45em solid $color-C6A14A;
    -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
    -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
    box-shadow: 0px 1px 6px 0.6px $color-00000029;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .first_desc {
      @extend %DescStyle;
      color: $color-202020;
      text-transform: capitalize;
      max-width: 85%;
      margin-bottom: 1em;
    }
    .feature_wrapper {
      .feature_title {
        @include textStyles(1.563em, 1.7em, 0);
        color: $color-202020;
        margin-bottom: 1em;
      }
      .title_list {
        @extend %DescStyle;
        color: $color-202020;
        position: relative;
        padding-left: 1em;
        &::before {
          content: "";
          position: absolute;
          top: 0.6em;
          left: 0.1em;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $color-C4A049;
        }
      }
    }
    .recommendation_wrapper {
      padding-left: 6.75em;
      .recommendation_title {
        @include textStyles(1.563em, 1.7em, 0);
        color: $color-202020;
        margin-bottom: 1em;
      }
      .recommendation_list {
        @extend %DescStyle;
        color: $color-202020;
        position: relative;
        padding-left: 1em;
        &::before {
          content: "";
          position: absolute;
          top: 0.6em;
          left: 0.1em;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $color-C4A049;
        }
      }
    }
  }
}

.product_sec3 {
  margin: 0 0 9.375em;
  .text_container {
    position: relative;
    margin-bottom: 3.75em;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 0 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      padding-left: 3em;
      margin: 2.9em 0 0;
    }
  }
  .flex_box {
    display: flex;
    justify-content: center;
    margin-bottom: 3.761em;
    .table_title {
      @include textStyles(1.563em, 1.7em, 0.25px);
      color: $color-202020;
      font-weight: 500;
      padding: 0 2.5em;
    }
    .highly_title,
    .recommend_title,
    .sulphate_title,
    .chloride_title {
      position: relative;
      @include textStyles(1.563em, 1.7em, 0.25px);
      color: $color-202020;
      font-weight: 500;
      padding: 0 2.5em;
    }
    .highly_title {
      padding-left: 1.5em;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 0.8em;
        height: 0.8em;
        border-radius: 50%;
        background-color: $color-202020;
      }
    }
    .recommend_title {
      padding-left: 1.5em;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 0.79em;
        height: 0.79em;
        border: 1px solid $color-202020;
        border-radius: 50%;
        background-color: transparent;
      }
    }
    .sulphate_title {
      padding-left: 1.5em;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 0.8em;
        height: 0.8em;
        background-color: $color-C8DEFE;
      }
    }
    .chloride_title {
      padding-left: 1.5em;
      &::before {
        content: "";
        position: absolute;
        top: 0.5em;
        left: 0.1em;
        width: 0.8em;
        height: 0.8em;
        background-color: $color-FCE2B3;
      }
    }
  }
  .product_table {
    border: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
    text-align: center;
    width: 100%;
    .prouct_table__body {
      border: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
      .prouct_table__row {
        // border: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        .blank_heading {
          border-bottom: none;
        }
        .product_heading,
        .coatings_heading,
        .plastic_heading,
        .ink_heading,
        .product_name {
          border: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .product_name {
          text-align: left;
          @include textStyles(1.563em, 1.7em, 0.25px);
          text-transform: capitalize;
          font-weight: 500;
          color: $color-202020;
          max-width: 8.25em;
          padding: 0.3em 0 0.3em 0.4em;
        }
        .product_heading {
          @include textStyles(1.563em, 1.7em, 0.25px);
          text-transform: capitalize;
          font-weight: 600;
          border-top: none;
        }
        .coatings_heading,
        .plastic_heading,
        .ink_heading {
          @include textStyles(1.875em, 1.7em, 0.3px);
          text-transform: uppercase;
          font-weight: 500;
          color: $color-C6A14A;
          padding: 0.2em 0;
        }

        .prod1_feature3,
        .prod1_feature5,
        .prod2_feature3,
        .prod2_feature5,
        .prod3_feature3,
        .prod3_feature5,
        .prod4_feature3,
        .prod4_feature5 {
          border-right: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }

        .prod1_feature1,
        .prod1_feature2,
        .prod1_feature3,
        .prod1_feature4,
        .prod1_feature5,
        .prod1_feature6,
        .prod1_feature7 {
          background-color: $color-C8DEFE;
        }

        .prod2_feature1,
        .prod2_feature2,
        .prod2_feature3,
        .prod2_feature4,
        .prod2_feature5,
        .prod2_feature6,
        .prod2_feature7 {
          background-color: $color-C8DEFE;
          border-bottom: 0.8px solid $color-C8DEFE;
        }

        .prod3_feature1,
        .prod3_feature2,
        .prod3_feature3,
        .prod3_feature4,
        .prod3_feature5,
        .prod3_feature6,
        .prod3_feature7 {
          background-color: $color-FCE2B3;
          border-top: 0.8px solid $color-FCE2B3;
        }

        .prod4_feature1,
        .prod4_feature2,
        .prod4_feature3,
        .prod4_feature4,
        .prod4_feature5,
        .prod4_feature6,
        .prod4_feature7 {
          background-color: $color-FCE2B3;
        }

        td {
          border-bottom: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }

        .title1,
        .title2,
        .title3,
        .title4,
        .title5,
        .title6,
        .title7 {
          @include textStyles(1.563em, 1.7em, 0.25px);
          text-transform: capitalize;
          font-weight: 600;
        }

        .title1 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
          border-left: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title2 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title3 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
          border-right: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title4 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title5 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
          border-right: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title6 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }
        .title7 {
          border-top: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
          border-right: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
        }

        // product feautre dot starts here
        .prod1_feature1,
        .prod1_feature2,
        .prod2_feature4,
        .prod3_feature1,
        .prod3_feature2,
        .prod3_feature3,
        .prod4_feature4 {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 30%;
            left: 47%;
            width: 1.2em;
            height: 1.2em;
            border-radius: 50%;
            background-color: $color-202020;
          }
        }

        .prod1_feature3,
        .prod1_feature6,
        .prod1_feature7,
        .prod2_feature5,
        .prod4_feature5 {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 30%;
            left: 47%;
            width: 1.19em;
            height: 1.19em;
            border: 1px solid $color-202020;
            border-radius: 50%;
            background-color: transparent;
          }
        }
        // product feautre dot ends here
      }
    }
  }
}

.product_sec4 {
  margin: 0 0 9.375em;
  .difference_between {
    align-items: center;
  }
  .text_container {
    max-width: 85%;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 2.5em 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      margin: 1.667em 0 2.778em;
    }
  }
  .table_box {
    padding-left: 2.375em;
    .diff_table__wrapper {
      background-color: $color-F2F2F2;
      padding: 2.819em 3.646em 3.125em 3.625em;
      -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
      -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
      box-shadow: 0px 1px 6px 0.6px $color-00000029;
      z-index: 2;
      .diff_table {
        text-align: left;
        .diff_table__row {
          // border: 0.8px solid rgba($color: $color-C8C8C8, $alpha: 1);
          //first row content start here
          .blank_data,
          .chloride_title {
            text-align: center;
            @include textStyles(1.688em, 1.7em, 0.27px);
            color: $color-C6A14A;
            font-weight: 500;
            text-transform: uppercase;
            padding: 0 0 0.2em;
            border-right: 0.8px solid $color-C8C8C8;
            border-bottom: 0.8px solid $color-C8C8C8;
          }
          .sulphate_title {
            text-align: center;
            @include textStyles(1.688em, 1.7em, 0.27px);
            color: $color-C6A14A;
            font-weight: 500;
            text-transform: uppercase;
            padding: 0 0 0.2em;
            border-bottom: 0.8px solid $color-C8C8C8;
          }
          .point_name {
            @include textStyles(1.375em, 1.7em, 0.22px);
            font-weight: 600;
            text-transform: capitalize;
            text-align: left;
            border-bottom: 0.8px solid $color-C8C8C8;
            width: 10em;
          }
          .no_bottom_border {
            border-bottom: none;
          }
          .point1_desc1,
          .point1_desc2,
          .point2_desc1,
          .point2_desc2,
          .point3_desc1,
          .point3_desc2,
          .point4_desc1,
          .point4_desc2,
          .point5_desc1,
          .point5_desc2 {
            @extend %DescStyle;
            color: $color-202020;
            border-bottom: 0.8px solid $color-C8C8C8;
            padding: 1em;
            br {
              display: none;
            }
          }
          .point4_desc1,
          .point4_desc2 {
            br {
              display: none;
            }
          }
          .point5_desc1,
          .point5_desc2 {
            border-bottom: none;
            padding-bottom: 0;
          }

          .point1_desc1,
          .point2_desc1,
          .point3_desc1,
          .point4_desc1,
          .point5_desc1 {
            border-right: 0.8px solid $color-C8C8C8;
            border-left: 0.8px solid $color-C8C8C8;
          }
          //first row content end here
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .product_sec1 {
    margin: 6em 0;
    .product_boxes {
      .text_container {
        max-width: 100%;
        margin-bottom: 4em;
        .breadcrumb_text {
          margin: 0 0 1.167em;
        }
        .desc {
          margin: 0.667em 0 1.778em;
        }
      }
      .image_container {
        .chemical_img {
          transform: scale(1);
        }
      }
    }
  }

  .product_sec2 {
    .my_container {
      padding: 0 0 0 15px !important;
    }
    .left_section {
      .lefttab {
        @include textStyles(1.8em, 1.7em, 0.18px);
        text-align: center;
        padding: 0.7em 0.5em 0;
        // margin: 1.1em 0em 1.1em 0em;
      }
      .lefttab.active {
        padding: 0.7em 0.5em 0;
        // margin: 1.1em 0em 1.1em 0em;
      }
    }
    .rightbox {
      padding: 3em 1em;
      .first_desc {
        font-size: 1.6em;
        max-width: 100%;
      }
      .feature_wrapper {
        .feature_title {
          font-size: 2em;
        }
        .title_list {
          font-size: 1.6em;
        }
      }
      .recommendation_wrapper {
        margin-top: 1em;
        padding-left: 0;
        .recommendation_title {
          font-size: 2em;
        }
        .recommendation_list {
          font-size: 1.6em;
        }
      }
    }
  }

  .product_sec3 {
    .text_container {
      margin-bottom: 0;
      .desc {
        padding-left: 0;
        margin: 1em 0 2em;
      }
    }
    .flex_box {
      justify-content: flex-start;
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 0.5em;
      .table_title {
        padding: 0 1em 0 0;
      }
    }
    // .flex_box::-webkit-scrollbar {
    //   display: none;
    // }
    // .product_table::-webkit-scrollbar {
    //   display: none;
    // }
    .product_table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      .prouct_table__body {
        display: table;
        width: 100%;
        .prouct_table__row {
          .coatings_heading,
          .plastic_heading,
          .ink_heading {
            font-size: 2em;
          }
          .product_heading,
          .title1,
          .title2,
          .title3,
          .title4,
          .title5,
          .title6,
          .title7 {
            width: 100%;
            font-size: 1.8em;
            padding: 0.2em 1em;
          }
          .product_name {
            font-size: 1.6em;
            padding-right: 1em;
            width: 100%;
            max-width: 15em;
          }
        }
      }
    }
  }

  .product_sec4 {
    .text_container {
      max-width: 100%;
      .breadcrumb_text {
        margin-top: 0;
      }
    }
    .table_box {
      .diff_table__wrapper {
        overflow-x: auto;
        white-space: nowrap;
        .diff_table {
          .diff_table__row {
            .chloride_title,
            .sulphate_title {
              font-size: 2em;
            }
            .point_name {
              padding-right: 2em;
              font-size: 1.8em;
            }
            .point1_desc1,
            .point1_desc2,
            .point2_desc1,
            .point2_desc2,
            .point3_desc1,
            .point3_desc2,
            .point4_desc1,
            .point4_desc2,
            .point5_desc1,
            .point5_desc2 {
              font-size: 1.6em;
              br {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .product_sec1 {
    .product_boxes {
      .text_container {
        margin-bottom: 0;
        .desc {
          margin-top: 1.5em;
        }
        .chemical_img {
          margin-bottom: 3em;
        }
        .btn_wrapper {
          display: flex;
          justify-content: center;
          .common_btn {
            border: 1px solid $color-BE8E34 !important;
            color: $color-000000 !important;
          }
        }
      }
    }
  }

  .product_sec2 {
    margin-bottom: 6em;
    .my_container {
      padding: 0 15px !important;
    }
    .subheading_box {
      text-align: left;
      margin-bottom: 2em;
      .heading {
        br {
          display: none;
        }
      }
    }
    .rightbox {
      border: none;
      box-shadow: none;
      border-bottom: 0.8px solid $color-C8C8C8;
      .feature_wrapper {
        .feature_title {
          font-size: 2em;
          margin-bottom: 0.5em;
        }
      }
      .recommendation_wrapper {
        .recommendation_title {
          font-size: 2em;
          margin-bottom: 0.5em;
        }
      }
    }
    .mb_faq {
      ul {
        padding-left: 0;
      }
      text-align: left;
      .mb_main_li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @include textStyles(1.8em, 1.5em, 0px);
        text-transform: uppercase;
        color: $color-202020;
        font-weight: 400;
        padding: 0.5em 0;
        border-bottom: 0.8px solid $color-C8C8C8;
        margin: 0;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        .plus_minus {
          width: 100%;
          max-width: 1em;
          margin-left: 1em;
        }
      }
      .mb_main_li.active {
        position: relative;
        color: $color-058F39;
        transition: 0.3s all ease-in-out;
      }

      .mb_faq_sub_menu--list_wrapper {
        display: none;
      }
      .mb_faq_sub_menu--list_wrapper.active {
        display: block;
      }
    }
    .rightbox {
      padding: 1em 0.2em 1em 0.2em;
      .right_heading {
        max-width: 100%;
        font-size: 2.4em;
        line-height: 1.5em;
      }
      .right_desc,
      .right_desc2 {
        letter-spacing: 0.24px;
        line-height: 1.7em;
        max-width: 100%;
        font-size: 1.3em;
      }
    }
  }

  .product_sec3 {
    margin-bottom: 5em;
    .text_container {
      .heading {
        br {
          display: none;
        }
      }
    }
    .flex_box {
      .table_title,
      .highly_title,
      .recommend_title,
      .sulphate_title,
      .chloride_title {
        font-size: 1.8em;
      }
    }
    .product_table {
      .prouct_table__body {
        .prouct_table__row {
          .title1,
          .title2,
          .title3,
          .title4,
          .title5,
          .title6,
          .title7,
          .product_heading,
          .product_name {
            @include textStyles(2.5em, 1.7em, 0.25px);
          }
          .product_name {
            padding-right: 0.3em;
          }
          .coatings_heading,
          .plastic_heading,
          .ink_heading {
            font-size: 2.8em;
          }
          .prod1_feature1,
          .prod1_feature2,
          .prod2_feature4,
          .prod3_feature1,
          .prod3_feature2,
          .prod3_feature3,
          .prod4_feature4,
          .prod1_feature3,
          .prod1_feature6,
          .prod1_feature7,
          .prod2_feature5,
          .prod4_feature5 {
            position: relative;
            &::before {
              top: 40%;
            }
          }
        }
      }
    }
  }

  .product_sec4 {
    .text_container {
      .desc {
        margin-top: 1.5em;
      }
    }
    .table_box {
      padding-left: 15px;
      .diff_table__wrapper {
        padding-left: 5.8em;
        .diff_table {
          .diff_table__row {
            .point_name {
              @include textStyles(2.2em, 1.7em, 0.22px);
              padding-right: 5em;
            }
            .chloride_title,
            .sulphate_title {
              @include textStyles(2.5em, 1.7em, 0.25px);
            }
            .point1_desc1,
            .point1_desc2,
            .point2_desc1,
            .point2_desc2,
            .point3_desc1,
            .point3_desc2,
            .point4_desc1,
            .point4_desc2,
            .point5_desc1,
            .point5_desc2 {
              padding: 1em 2em;
            }
          }
        }
      }
    }
  }
}
