@import "../../common.scss";
.thankyousec {
  margin: 7.5em 0 9.375em;
  padding-top: 8.25em;
  padding-bottom: 8.25em;
  background-image: url("../../images/general/bg404.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .thanklogo {
    width: 100%;
    max-width: 20.25em;
  }
  .thank_heading {
    color: $color-202020;
    font-size: 9.375em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0.4em;
  }
  .thank_desc {
    color: $color-202020;
    font-size: 2.813em;
    opacity: 0.8;
    font-weight: 600;
  }
  .thank_return {
    font-size: 2em;
    margin-top: 1.5em;
    opacity: 1;
    font-weight: 600;
    color: $color-202020;
    .link_to_home {
      color: $color-202020;
    }
  }
}
@media (max-width: 767px) {
  .thankyousec {
    margin: 6em 0;
    padding-top: 17.4em;
    padding-bottom: 17.4em;
    .thanklogo {
      max-width: 15.6em;
    }
    .thank_heading {
      font-size: 4.8em;
    }
    .thank_desc {
      font-size: 2em;
    }
  }
}
