@import "../../common.scss";

.about_sec1 {
  margin: 7.5em 0 9.375em;
  .about_us_section {
    align-items: center;
    .text_container {
      max-width: 90%;
      .breadcrumb_text {
        @extend %BreadCrumb;
        margin: 2.5em 0 0.667em;
      }
      .heading {
        @extend %HeadingStyle;
      }
      .desc {
        @extend %DescStyle;
        margin: 0.667em 0 1.578em;
      }
      .mission_container {
        display: flex;
        .mission_icon {
          //   max-width: 1.291em;
          margin-right: 0.619em;
        }
        .mission_heading {
          @include textStyles(1.375em, 1.7em, 0.22px);
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .mission_desc {
        @extend %DescStyle;
        margin: 0.667em 0 1.578em;
      }
    }
    .image_container {
      overflow: hidden;
      margin: 2.5em 0 0;
      .aboutus_img {
        width: 100%;
        transform: scale(1.2);
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}

.about_sec2 {
  margin: 9.444em 0 8.743em;
  .stock_section {
    align-items: center;
    .text_container {
      max-width: 100%;
      .breadcrumb_text {
        @extend %BreadCrumb;
        margin: 2.5em 0 1.167em;
      }
      .heading {
        @extend %HeadingStyle;
      }
      .desc {
        @extend %DescStyle;
        margin: 1.667em 0 2.5em;
      }
      .city_list {
        .list_item {
          @include textStyles(1.375em, 1.7em, 0.22px);
          position: relative;
          font-weight: 500;
          padding-left: 1.231em;
          margin: 0.625em 0;
          &::before {
            content: "";
            position: absolute;
            top: 0.6em;
            left: 0.1em;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $color-C4A049;
          }
        }
      }
    }
    .image_container {
      overflow: hidden;
      padding: 2.5em 5em;
      .map_img {
        width: 100%;
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.about_sec3 {
  margin: 0 0 9.375em;
  .text_container {
    position: relative;
    margin-bottom: 3.75em;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 0 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
      max-width: 95%;
    }
    .desc {
      @extend %DescStyle;
      padding-left: 3em;
      margin: 2.9em 0 0;
    }
  }
  .team_box {
    .team_swiper {
      padding: 0.5em 0.5em 0;
      .content_box {
        text-align: center;
        width: 100%;
        width: 100%;
        max-width: 96%;
        height: 100%;
        // margin: 0 1em;
        padding: 1.254em 0.2em 0.899em;
        border-radius: 1px;
        border-bottom: 0.45em solid $color-C6A14A;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        box-shadow: 0px 1px 6px 0.6px $color-00000029;
        z-index: 2;
        &:hover {
          box-shadow: none;
          background-color: rgba($color: $color-C6A14A, $alpha: 0.5);
          border-bottom: 0.45em solid rgba($color: $color-C6A14A, $alpha: 0.1);
        }
        .profile_icon {
          max-width: 4.138em;
          margin-bottom: 0.642em;
        }
        .heading {
          text-transform: uppercase;
          color: $color-202020;
          font-weight: 700;
        }
        .desc {
          @extend %DescStyle;
          color: $color-202020;
          margin-top: 0.614em;
        }
      }
      .swiper-pagination {
        display: none;
      }
    }
  }
}

.about_sec4 {
  margin-bottom: 9.375em;
  .adjusted_row {
    align-items: center;
  }
  .text_container {
    max-width: 100%;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 0 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      margin: 1.667em 0 1em;
    }
    .award_list {
      .list_item {
        @extend %DescStyle;
        position: relative;
        padding-left: 1.231em;
        margin: 0.312.5em 0;
        &::before {
          content: "";
          position: absolute;
          top: 0.6em;
          left: 0.1em;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $color-C4A049;
        }
      }
    }
  }
  .certificate_swiper_wrapper {
    .certificate_swiper {
      .certificate_slide {
        padding: 5em 0;
        .certificate_img {
          width: 100%;
          max-width: 23em;
          transform: scale(1);
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .swiper-pagination {
      display: none;
    }
  }
}

@media (max-width: 2560px) {
  .about_sec3 {
    .team_box {
      .team_swiper {
        .content_box {
          .heading {
            @include textStyles(1.3em, 1.7em, 0.25px);
          }
        }
      }
    }
  }
}

@media (max-width: 1560px) {
  .about_sec3 {
    .team_box {
      .team_swiper {
        .content_box {
          .heading {
            @include textStyles(1.5625em, 1.7em, 0.25px);
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .about_sec3 {
    .team_box {
      .team_swiper {
        .content_box {
          .heading {
            @include textStyles(1.3em, 1.7em, 0.25px);
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .about_sec1 {
    margin: 6em 0;
    .about_us_section {
      .text_container {
        max-width: 100%;
        margin-bottom: 4em;
        .breadcrumb_text {
          margin: 0 0 1.167em;
        }
        .desc {
          margin: 0.667em 0 1.778em;
        }
        .mission_container {
          .mission_heading {
            font-size: 2.2em;
          }
        }
        .mission_desc {
          margin: 0.667em 0 1.778em;
        }
      }
      .image_container {
        .aboutus_img {
          transform: scale(1);
        }
      }
    }
  }

  .about_sec2 {
    margin: 6em 0;
    .stock_section {
      .text_container {
        max-width: 100%;
        margin-bottom: 4em;
        .breadcrumb_text {
          margin: 0 0 1.167em;
        }
        .desc {
          margin: 0.667em 0 1.778em;
        }
        .city_list {
          .list_item {
            font-size: 1.6em;
          }
        }
      }
    }
  }

  .about_sec3 {
    .text_container {
      margin-bottom: 0;
      .desc {
        padding-left: 0;
        margin: 1em 0 0;
      }
    }
    .team_box {
      margin: 6em 0;
      .team_swiper {
        .content_box {
          margin-right: 2em;
          padding: 2.063em 1.438em 2.563em;
          margin: 0 0 5em 0;
          .heading {
            font-size: 1.8em;
          }
        }
        .swiper-pagination {
          display: block;
          .swiper-pagination-bullet-active {
            background-color: $color-929497;
          }
        }
      }
    }
  }

  .about_sec4 {
    .text_container {
      max-width: 100%;
      margin-bottom: 4em;
      .breadcrumb_text {
        margin: 0 0 1.167em;
      }
      .desc {
        margin: 0.667em 0 1.778em;
      }
    }
    .certificate_swiper_wrapper {
      .certificate_swiper {
        .certificate_slide {
          text-align: center;
          padding: 0;
          margin-bottom: 5em;
          .certificate_img {
            max-width: 100%;
            &:hover {
              transform: scale(1);
            }
          }
        }
      }
      .swiper-pagination {
        display: block;
        .swiper-pagination-bullet-active {
          background-color: $color-929497;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .about_sec1 {
    .about_us_section {
      .text_container {
        margin-bottom: 0;
        .desc {
          margin-top: 1.5em;
        }
        .chemical_img {
          margin-bottom: 3em;
        }
        .btn_wrapper {
          display: flex;
          justify-content: center;
          .common_btn {
            border: 1px solid $color-BE8E34 !important;
            color: $color-000000 !important;
          }
        }
        .mission_heading {
          @include textStyles(2em, 1.7em, 0.2px);
        }
        .mission_desc {
          margin-top: 1em;
        }
      }
    }
  }

  .about_sec2 {
    .stock_section {
      .text_container {
        margin-bottom: 0;
        .breadcrumb_text {
          @include textStyles(1.4em, 1.7em, 0.14px);
        }
        .desc {
          margin-top: 1.5em;
        }
        .city_list {
          display: none;
        }
      }
      .image_container {
        padding: 0;
      }
    }
  }

  .about_sec3 {
    margin: 6em 0;
    .text_container {
      .heading {
        max-width: 100%;
        br {
          display: none;
        }
      }
    }
    .team_box {
      .team_swiper {
        .content_box {
          margin: 1em 0 4em;
          max-width: 100%;
          &:hover {
            -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            box-shadow: 0px 1px 6px 0.6px $color-00000029;
            background-color: $color-ffffff;
            border-bottom: 0.45em solid $color-C6A14A;
          }
          .profile_icon {
            max-width: 5.1em;
            margin-bottom: 2em;
          }
          .heading {
            @include textStyles(1.8em, 1.7em, 0.2px);
          }
          .desc {
            padding: 0 1em;
          }
        }
      }
    }
  }

  .about_sec4 {
    margin: 6em 0;
    .text_container {
      margin-bottom: 0;
      .desc {
        margin-top: 1.5em;
      }
    }
    .certificate_swiper_wrapper {
      margin-top: 5.5em;
    }
  }
}
