/*============================
    General css start Here    
============================*/
$color-ffffff: #ffffff;
$color-000000: #000000;
$color-202020: #202020;
$color-C6A14A: #c6a14a;
$color-C4A049: #c4a049;
$color-058F39: #058f39;
$color-F2F2F2: #f2f2f2;
$color-C8DEFE: #c8defe;
$color-FCE2B3: #fce2b3;
$color-1D1D1D: #1d1d1d;
$color-929497: #929497;
$color-BE8E34: #be8e34;
$color-8B8B8B: #8b8b8b;
$color-C8C8C8: #c8c8c8;
$color-7F8080: #7f8080;
$color-00000029: #00000029;

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  background-color: $color-C8C8C8;
}
::-webkit-scrollbar-thumb {
  background-color: $color-000000;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

input:focus,
textarea:focus,
form-control:focus {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

ul,
li,
a:visited,
a:active,
a:focus {
  outline: none;
  border: none;
  text-decoration: none;
  box-shadow: none;
  outline-style: none;
  -moz-outline-style: none;
}
a:active,
a:focus,
li:focus,
li:active {
  outline: none;
  border: none;
  text-decoration: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

html,
body,
#root {
  height: 100%;
}

img {
  height: auto;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
a {
  text-decoration: none;
  color: $color-000000;
  &:hover {
    text-decoration: none;
    color: $color-000000;
    outline: none;
  }
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
li {
  list-style-type: none;
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 50;
  background-color: #fff;
  .loader_img {
    width: 100%;
    max-width: 9.2em;
  }
}

.whatsapp_wrapper {
  position: fixed;
  right: 2em;
  bottom: 4em;
  z-index: 4;
  .whatsapp_icon {
    max-width: 5em;
  }
}

table {
  border-collapse: collapse;
  tbody {
    border-collapse: collapse;
  }
  tr {
    border-collapse: collapse;
  }
  td {
    border-collapse: collapse;
  }
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2186.66px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1487.5px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1600px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: 1350.5px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1440px) {
  body {
    font-size: 10.5px;
  }
  .my_container {
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .whatsapp_wrapper {
    .whatsapp_icon {
      max-width: 3.5em;
    }
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1195.25px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 9.5px;
  }
  .my_container {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1044.75px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 729.75px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1014.27px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 708.46px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .whatsapp_wrapper {
    .whatsapp_icon {
      max-width: 4em;
    }
  }
}

/*============================
      General css end Here    
  ============================*/

// .loader_wrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   min-height: 100vh;
//   z-index: 50;
//   background-color: #fff;
//   .loader_img {
//     width: 100%;
//     max-width: 9.2em;
//   }
// }

/*============================
    MIXINS start Here    
    MIXINS start Here    
    MIXINS start Here    
    MIXINS start Here    
    MIXINS start Here    
============================*/
@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
/*============================
    MIXINS end Here    
============================*/

// Extends starts here
// Extends starts here
// Extends starts here
// Extends starts here

%BreadCrumb {
  @include textStyles(1.375em, 1.5em, 0.18px);
  font-weight: 600;
  color: $color-202020;
  opacity: 1;
  text-transform: uppercase;
}

%HeadingStyle {
  @include textStyles(2.188em, 1.7em, 0.35px);
  font-weight: 600;
  color: $color-202020;
  text-transform: capitalize;
}

%DescStyle {
  @include textStyles(1.345em, 1.7em, 0.4px);
  font-weight: 400;
  color: $color-202020;
}
@media (max-width: 992px) {
  %BreadCrumb {
    font-size: 1.8em;
  }

  %HeadingStyle {
    font-size: 2.2em;
  }

  %DescStyle {
    font-size: 1.6em;
  }
}
@media (max-width: 600px) {
  %BreadCrumb {
    @include textStyles(1.4em, 1.7em, 0.14px);
  }

  %HeadingStyle {
    @include textStyles(2em, 1.7em, 0.2px);
  }
}
// Extends ends here
// Extends ends here
// Extends ends here
// Extends ends here
