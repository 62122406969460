@import "../../common.scss";
.not_found_sec {
  margin-top: 7.5em;
  padding-top: 8.25em;
  padding-bottom: 8.25em;
  background-image: url("../../images/general/bg404.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  .oops_text {
    @extend %HeadingStyle;
  }
  .logo404 {
    width: 100%;
    max-width: 44.813em;
  }
  .not_desc {
    color: $color-202020;
    font-size: 2em;
    font-weight: 600;
    text-transform: uppercase;
  }
  .not_return {
    font-size: 2em;
    margin-top: 1em;
    opacity: 0.9;
    font-weight: 600;
    color: $color-202020;
    .link_to_home {
      color: $color-202020;
    }
  }
}
@media (max-width: 767px) {
  .not_found_sec {
    margin-top: 6em;
    padding-top: 17.4em;
    padding-bottom: 17.4em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    .not_desc {
      font-size: 1.6em;
    }
    .not_return {
      font-size: 1.6em;
    }
  }
}
