@import "../../common.scss";
.common_banner_wrapper {
  position: relative;
  margin-top: 6.667em;
  .common_bannerimg {
    width: 100%;
    object-fit: cover;
  }
  .breadcrumb_content_wrapper {
    font-size: 1.125em;
    position: absolute;
    top: 1.226em;
    .breadcrumb_text {
      color: $color-202020;
      text-transform: uppercase;
      font-weight: 700;
      .fa-greater-than {
        transform: scaleX(0.6);
      }
    }
  }
  .text_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .breadcrumb_text.disabled {
    pointer-events: none;
  }
  .banner_heading {
    font-size: 3.75em;
    font-weight: 700;
    margin-top: 0.13em;
    color: $color-202020;
    text-transform: uppercase;
  }
}
@media (max-width: 992px) {
  .common_banner_wrapper {
    .breadcrumb_content_wrapper {
      .breadcrumb_text {
        display: none;
      }
    }
  }
}
@media (max-width: 600px) {
  .common_banner_wrapper {
    .common_bannerimg {
      min-height: 20em;
    }
    .banner_heading {
      font-size: 3.5em;
    }
  }
}
