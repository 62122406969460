@import "../../common.scss";

.comform_sec1 {
  margin: 0 0 6.25em;
  .heading {
    @include textStyles(1.875em, 1.7em, 0.3px);
    text-transform: uppercase;
    color: $color-202020;
    font-weight: 500;
    margin-bottom: 1.5em;
  }
  .form_container {
    max-width: 100%;
    .error {
      position: absolute;
      text-transform: capitalize;
      font-size: 1em;
      font-weight: 500;
      margin: 0.5em 0;
      color: red;
    }
    .material-textfield {
      position: relative;
      margin-top: 1.438em;
      background-color: transparent;
      label {
        position: absolute;
        background-color: transparent;
        @include textStyles(1em, 1em, 0px);
        font-weight: 500;
        color: $color-202020;
        left: 0;
        top: 50%;
        padding: 1.5em 0 0em 0.3em;
        margin: 0 0.3em;
        transform: translateY(-100%) scale(1.2);
        pointer-events: none;
        transform-origin: left top;
        transition: 0.1s ease-out;
        opacity: 0.8;
      }
      input {
        @include textStyles(1em, 1em, 0px);
        background-color: transparent;
        padding: 0.5em 0 0.5em 0.3em;
        font-weight: 500;
        color: $color-202020;
        width: 100%;
        max-width: 95%;
        outline: none;
        border: none;
        transition: 0.1s ease-out;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
      }
      input:focus {
        border-color: $color-202020;
        border-bottom: 1px solid rgba(0, 0, 0, 1);
      }
      input:focus + label {
        color: $color-202020;
        top: 0;
        font-size: 0.738em;
        transform: translateY(-90%) scale(1);
        transition: all 0.3s ease-in;
      }
      input:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-90%) scale(1);
        font-size: 0.738em;
      }
    }

    .cta_wrapper {
      margin: 1.438em 0 0;
      margin-right: 8em;
      display: flex;
      justify-content: flex-end;
      button {
        @include textStyles(1.125em, 1.7em, 0.18px);
        margin: 0.1em 0;
        width: 100%;
        max-width: 14.334em;
        height: 3.445em;
        outline: 0;
        border-radius: 2em;
        text-transform: capitalize;
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-000000;
        cursor: pointer;
        background-color: $color-ffffff;
        border: 1px solid $color-BE8E34;
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:hover {
          border: none;
          color: $color-ffffff;
          background: linear-gradient(
            90deg,
            rgba(190, 142, 52, 1) 50%,
            rgba(235, 201, 139, 1) 100%
          );
        }
      }
      .send {
        @include textStyles(1.2em, 1em, 0.2px);
        width: 100%;
        max-width: 12.5em;
        height: 3.5em;
        padding: 1em 0em;
        outline: 0;
        background-color: transparent;
        text-transform: capitalize;
        opacity: 0.8;
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2em;
        color: $color-ffffff;
        background-color: $color-202020;
        transition: all 0.3s ease-in;
        box-shadow: 0 4px 12px var(--shadow);
        transform: translateY(var(--y));
        transition: box-shadow 0.3s ease, transform 0.3s ease;
        &:hover {
          --y: -2px;
          box-shadow: 0 8px 16px var(--shadow);
        }
        &:active {
          --y: 1px;
          box-shadow: 0 4px 8px var(--shadow);
        }
        img {
          margin-left: 1em;
          max-width: 1.8em;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .comform_sec1 {
    .form_container {
      .cta_wrapper {
        margin-right: 4em;
      }
    }
  }
}

@media (max-width: 600px) {
  .comform_sec1 {
    margin-bottom: 4em;
    .heading {
      margin-bottom: 0.5em;
    }
    .form_container {
      .material-textfield {
        margin: 1em 0 3em;
        input {
          border-bottom: 0.3px solid rgba(0, 0, 0, 0.7);
        }
        input:focus + label {
          transform: translateY(-70%) scale(1);
        }
        input:not(:placeholder-shown) + label {
          transform: translateY(-70%) scale(1);
        }
      }
      .cta_wrapper {
        justify-content: flex-start;
        margin: 1.5em 0 0;
      }
    }
  }
}
