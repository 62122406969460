@import "../../common.scss";
.common_btn {
  @include textStyles(1.125em, 1.7em, 0.18px);
  color: $color-202020;
  margin: 0.1em 0;
  width: 100%;
  max-width: 14.334em;
  height: 3.445em;
  outline: 0;
  border-radius: 2em;
  border: 1px solid $color-BE8E34 !important;
  background-color: transparent;
  text-transform: capitalize;
  opacity: 1;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  &:hover {
    border: none !important;
    color: $color-ffffff;
    background: linear-gradient(
      90deg,
      rgba(190, 142, 52, 1) 50%,
      rgba(235, 201, 139, 1) 100%
    );
  }
}

@media (max-width: 992px) {
  .common_btn {
    @include textStyles(1.4em, normal, 0);
    width: 100%;
    max-width: 9.929em;
    height: 3.071em;
  }
}
