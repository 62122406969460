@import "../../common.scss";

.footer_sec1 {
  position: relative;
  // margin-top: 20em;
  background-color: $color-F2F2F2;
  color: $color-202020;
  padding: 3.25em 0 3.357em;
  .col-lg-5,
  .col-lg-4,
  .col-lg-3 {
    padding: 0;
  }
  .scrollup_img {
    position: absolute;
    top: -2.3em;
    right: 4.125em;
    cursor: pointer;
    max-width: 4.625em;
  }
  .social_icon_box {
    .logo_social {
      .footer_logo {
        margin-bottom: 1.7em;
      }
      .social_list {
        position: absolute;
        bottom: 4.67em;
        display: flex;
        align-items: center;
        .list_item {
          .icons {
            width: 100%;
            max-width: 5em;
            padding-right: 3.125em;
            img {
              background-color: $color-202020;
              color: $color-202020;
              opacity: 1;
            }
          }
          .facebook {
            max-height: 2.2em;
          }
        }
      }
    }
  }
  .links_sec {
    .links_flex {
      .link_heading {
        margin-bottom: 1.7em;
        font-size: 1.75em;
        font-weight: 500;
        text-transform: uppercase;
      }
      .link_list {
        .list_item {
          font-size: 1.25em;
          font-weight: 500;
          color: $color-202020;
          margin-bottom: 1em;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .connect_sec {
    padding-left: 4.5em;
    .connect_flex {
      .connect_heading {
        margin-bottom: 1.7em;
        padding-left: 1.5em;
        font-size: 1.75em;
        font-weight: 500;
        text-transform: uppercase;
      }
      .connect_list {
        .addressfield {
          align-items: flex-start !important;
          .connect_icons {
            margin-top: 0.5em;
          }
        }
        .list_item {
          display: flex;
          align-items: center;
          margin-bottom: 2.313em;
          .connect_icons {
            // width: 100%;
            max-width: 3em;
          }
          .list_desc {
            font-size: 1.25em;
            color: $color-202020;
            padding-left: 1.2em;
          }
          .contactno,
          .emailid {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.footer_sec2 {
  background-color: $color-C6A14A;
  color: $color-ffffff;
  padding: 0.625em 0 0.563em;
  .copyright_flex {
    text-align: center;
    font-size: 0.875em;
    letter-spacing: 0.24px;

    p {
      font-size: 1.285em;
      font-weight: 300;
      color: $color-ffffff;
    }
    a {
      font-weight: 300;
      color: $color-ffffff;
      &:hover {
        padding-bottom: 0.05em;
        border-bottom: 1px solid $color-ffffff;
      }
    }
  }
}

@media (max-width: 1600px) {
  .footer_sec1 {
    .footer_logo {
      max-width: 19.488em;
    }
  }
}

@media (max-width: 992px) {
  .footer_sec1 {
    .col-lg-5,
    .col-lg-4,
    .col-lg-3 {
      padding: 0 15px;
    }
    .scrollup_img {
      top: -2em;
      right: 2em;
      max-width: 4em;
    }
    .social_icon_box {
      .logo_social {
        .footer_logo {
          margin-bottom: 3.83em;
          max-width: 20.5em;
        }
      }
    }
    .links_sec {
      margin-bottom: 4.6em;
      .links_flex {
        .link_heading {
          margin-bottom: 1em;
        }
        .link_list {
          .list_item {
            font-size: 1.4em;
            margin-bottom: 1em;
          }
        }
      }
    }
    .connect_sec {
      padding-left: 0;
      .connect_flex {
        .connect_heading {
          padding-left: 0;
          margin-bottom: 1.5em;
        }
        .connect_list {
          margin-bottom: 5em;
          .list_item {
            margin-bottom: 1.313em;

            .list_item {
              &:nth-child(n + 1) {
                padding-left: 0;
              }
            }
          }
        }
      }
      .social_list {
        display: flex;
        align-items: center;
        justify-content: center;
        .list_item {
          .icons {
            width: 100%;
            padding: 0 2.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer_sec1 {
    text-align: center;
    .col-lg-5,
    .col-lg-4,
    .col-lg-3 {
      padding: 0 15px;
    }
    .scrollup_img {
      top: -1.3em;
      right: 1.5em;
      max-width: 3em;
    }
    .social_icon_box {
      .logo_social {
        .footer_logo {
          margin-bottom: 3.83em;
          max-width: 20.5em;
        }
      }
    }
    .links_sec {
      margin-bottom: 4.6em;
      .links_flex {
        .link_heading {
          margin-bottom: 1em;
        }
        .link_list {
          .list_item {
            font-size: 1.4em;
            margin-bottom: 1em;
          }
        }
      }
    }
    .connect_sec {
      padding-left: 0;
      .connect_flex {
        .connect_heading {
          padding-left: 0;
          margin-bottom: 1.5em;
        }
        .connect_list {
          margin-bottom: 5em;
          .list_item {
            justify-content: center;
            margin-bottom: 1.313em;
            .list_desc {
              padding-left: 0.5em;
              max-width: 83%;
            }
            .list_item {
              &:nth-child(n + 1) {
                padding-left: 0;
              }
            }
          }
        }
      }
      .social_list {
        display: flex;
        align-items: center;
        justify-content: center;
        .list_item {
          .icons {
            width: 100%;
            padding: 0 2.5em;
          }
        }
      }
    }
  }

  .footer_sec2 {
    .copyright_flex {
      a,
      p {
        font-size: 1em;
      }
    }
  }
}
