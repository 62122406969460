@import "../../common.scss";

.home_sec1 {
  padding-top: 6.667em;
  .banner_sec {
    position: relative;
    .banner_img {
      width: 100%;
      max-width: 100%;
      object-fit: fill;
      max-height: 90vh;
    }
    .banner_text {
      text-align: center;
      position: absolute;
      top: 53.5%;
      left: 13%;
      display: flex;
      max-width: 70%;
      color: $color-ffffff;
      font-size: 4.063em;
      font-weight: 600;
    }
    .scrolldown_img {
      position: absolute;
      display: flex;
      bottom: 1.778em;
      left: 49.6%;
      cursor: pointer;
      max-width: 1.517em;
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
    .btn_wrapper {
      position: absolute;
      width: 100%;
      max-width: 20.334em;
      bottom: 8em;
      left: 43.3%;
      .common_btn {
        border: 1px solid $color-BE8E34 !important;
        color: $color-ffffff !important;
      }
    }
  }
}

.home_sec2 {
  margin-top: 5.875em;
  .counter_box__wrapper {
    display: flex;
    // justify-content: center;
    justify-content: space-around;
    .counter_box {
      text-align: left;
    }
    // .count_title {
    //   margin: 0 2em;
    // }
  }
}

.home_sec3 {
  margin: 9.444em 0 9.375em;
  .text_container {
    max-width: 86%;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 2.5em 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      margin: 1.667em 0 2.778em;
    }
  }
  .image_container {
    overflow: hidden;
    .chemical_img {
      width: 100%;
      transform: scale(1.2);
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1);
      }
    }
  }
}

.home_sec4 {
  margin: 0 0 7.313em;
  .subheading_box {
    text-align: center;
    margin-bottom: 1.875em;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin-bottom: 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
  }
  .box_container {
    .whyswiper {
      padding: 0.5em 0.5em 0;
      .content_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0.5 0.15em;
        text-align: center;
        width: 100%;
        height: 100%;
        max-width: 98.5%;
        min-height: 23.015em;
        max-height: 23.015em;
        padding: 2.063em 2.438em 3.563em;
        border-radius: 1px;
        border-bottom: 0.45em solid $color-C6A14A;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        box-shadow: 0px 1px 6px 0.6px $color-00000029;
        z-index: 2;
        &:hover {
          box-shadow: none;
          background-color: rgba($color: $color-C6A14A, $alpha: 0.5);
          border-bottom: 0.45em solid rgba($color: $color-C6A14A, $alpha: 0.1);
        }
        .box_icon {
          max-width: 2.946em;
          margin-bottom: 0.642em;
        }
        .heading {
          @include textStyles(1.875em, 1.7em, 0.3px);
          text-transform: uppercase;
          color: $color-202020;
          font-weight: 700;
        }
        .desc {
          @extend %DescStyle;
          color: $color-202020;
        }
      }
      .swiper-pagination {
        display: none;
      }
    }
  }
}

.home_sec5 {
  .text_container {
    position: relative;
    margin-bottom: 1.75em;
    .breadcrumb_text {
      @extend %BreadCrumb;
      margin: 0 0 1.167em;
    }
    .heading {
      @extend %HeadingStyle;
    }
    .desc {
      @extend %DescStyle;
      padding-left: 3em;
      margin: 2.9em 0 0;
    }
  }
  .products_swiper {
    position: relative;
    .productsswiper {
      // padding: 0.5em 0.5em 0;
      .slider_box {
        display: flex;
        align-items: center;
        margin: 3.75em 1em 9.375em;
        -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
        box-shadow: 0px 1px 6px 0.6px $color-00000029;
        z-index: 2;
        cursor: text;
        &:hover {
          box-shadow: none;
          background-color: #00000009;
        }
        .heading {
          padding: 0 0 0 1.438em;
          @extend %HeadingStyle;
          font-weight: 500;
          color: $color-058F39;
        }
        .border_line {
          margin: 2.313em 1.875em;
          width: 0.1em;
          min-height: 19em;
          max-height: 19em;
        }
        .desc_wrapper {
          display: flex;
          flex-direction: column;
          .desc {
            @extend %DescStyle;
            margin-bottom: 1.25em;
            max-width: 95%;
          }
          .cta_wrapper {
            .know_more {
              @include textStyles(1.375em, 1.5em, 0);
              font-weight: 500;
              border: none;
              color: $color-C6A14A;
              &:hover {
                padding-bottom: 0.05em;
                border-bottom: 1px solid $color-C6A14A;
              }
            }
          }
        }
      }
      .swiper-pagination {
        display: none;
      }
    }
    .navigation_arrows_wrapper {
      .left_arrow {
        position: absolute;
        // background-color: $color-C6A14A;
        left: -4.375em;
        top: 14.5em;
        max-width: 7.5em;
        cursor: pointer;
        z-index: 2;
      }
      .right_arrow {
        position: absolute;
        right: -4.375em;
        top: 14.5em;
        max-width: 7.5em;
        cursor: pointer;
        z-index: 2;
      }
    }
  }
}

.home_sec6 {
  margin-bottom: 9.5em;
  .testimonials_swiper {
    background-image: url("../../images/Homepage/Group\ 3119.jpg");
    .testimonialsswiper {
      .testimonial_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5.563em 0 2.188em;
        .breadcrumb_text {
          @extend %BreadCrumb;
          color: $color-202020;
          margin-bottom: 1em;
        }
        .heading {
          @extend %HeadingStyle;
          color: $color-202020;
          text-transform: capitalize;
        }
        .quote_icon {
          max-width: 3.708em;
          margin: 1.649em 0 2.066em;
        }
        .desc {
          @extend %DescStyle;
          padding: 0 11.5em;
          color: $color-202020;
          text-align: center;
        }
        .underline_icon {
          max-width: 2.813em;
          margin: 1.844em 0 1.206em;
        }
        .testimonial_name {
          @include textStyles(1.125em, 1.7em, 0.18px);
          color: $color-202020;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    .navigations {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      @include textStyles(1.125em, 1.5em, 0);
      .navigation_left {
        margin: 0 0 1.938em 2.875em;
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        &:hover {
          font-weight: bold;
        }
      }
      .navigation_right {
        margin: 0 2.875em 1.938em 0;
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}

.home_sec7 {
  margin-bottom: 9.375em;
  .desc_brand_row {
    align-items: center;
    .text_container {
      position: relative;
      .breadcrumb_text {
        @extend %BreadCrumb;
        margin: 0 0 1.167em;
      }
      .heading {
        @extend %HeadingStyle;
        text-transform: capitalize;
      }
    }
    .brand_swiper {
      // padding-left: 6em;
      .brand_img1 {
        max-width: 17.75em;
      }
      .brand_img2 {
        max-width: 19.5em;
      }
    }
  }
}

@media (max-width: 1600px) {
  .home_sec1 {
    .banner_sec {
      .btn_wrapper {
        left: 44.3%;
      }
    }
  }
  .home_sec5 {
    .products_swiper {
      .navigation_arrows_wrapper {
        .left_arrow,
        .right_arrow {
          top: 13.5em;
        }
      }
    }
  }
  .home_sec6 {
    .testimonials_swiper {
      .testimonialsswiper {
        .testimonial_box {
          .desc {
            padding: 0 19.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .home_sec1 {
    .banner_sec {
      .btn_wrapper {
        left: 44%;
      }
    }
  }
}

@media (max-width: 992px) {
  .home_sec1 {
    .banner_sec {
      .banner_text {
        font-size: 3em;
        top: 35.5%;
        left: 0%;
        max-width: 100%;
      }
      .btn_wrapper {
        left: 42%;
        bottom: 10em;
      }
    }
  }

  .home_sec2 {
    margin-top: 6em;
    .counter_box__wrapper {
      justify-content: space-between;
    }
  }

  .home_sec3 {
    margin: 6em 0;
    .text_container {
      max-width: 100%;
      margin-bottom: 4em;
      .breadcrumb_text {
        margin: 0 0 1.167em;
      }
      .desc {
        margin: 0.667em 0 1.778em;
      }
    }
    .image_container {
      .chemical_img {
        transform: scale(1);
      }
    }
  }

  .home_sec4 {
    margin: 6em 0;
    .box_container {
      flex-wrap: wrap;
      .whyswiper {
        .content_box {
          max-width: 100%;
          min-height: 37.015em;
          max-height: 37.015em;
          padding: 2.063em 1.438em 2.563em;
          margin: 0 0 5em 0;
          .heading {
            font-size: 2.2em;
          }
          .desc {
            font-size: 1.8em;
          }
        }
        .swiper-pagination {
          display: block;
          .swiper-pagination-bullet-active {
            background-color: $color-202020;
          }
        }
      }
    }
  }

  .home_sec5 {
    .text_container {
      margin-bottom: 0;
      .desc {
        padding-left: 0;
        margin: 1em 0 0;
      }
    }
    .products_swiper {
      .productsswiper {
        .slider_box {
          .border_line {
            max-height: 20em;
          }
          .desc_wrapper {
            .cta_wrapper {
              .know_more {
                font-size: 1.4em;
              }
            }
          }
        }
      }
      .navigation_arrows_wrapper {
        .left_arrow,
        .right_arrow {
          top: 14.5em;
        }
      }
    }
  }

  .home_sec6 {
    margin: 0 0 6em;
    .testimonials_swiper {
      .testimonialsswiper {
        .testimonial_box {
          .desc {
            padding: 0 5em;
          }
          .testimonial_name {
            font-size: 1.6em;
          }
        }
      }
      .navigations {
        .navigation_left,
        .navigation_right {
          font-size: 1.4em;
        }
      }
    }
  }

  .home_sec7 {
    margin: 0 0 6em;
    .desc_brand_row {
      .text_container {
        margin-bottom: 4em;
      }
      .brand_swiper {
        .brand_img1 {
          max-width: 25em;
        }
        .brand_img2 {
          max-width: 28em;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home_sec1 {
    .banner_sec {
      .banner_img {
        max-height: 80vh;
      }
      .banner_text {
        padding: 0 1em;
        top: 8.5em;
      }
      .btn_wrapper {
        left: 31.5%;
        bottom: 8em;
      }
      .scrolldown_img {
        left: 48%;
      }
    }
  }

  .home_sec2 {
    .counter_box__wrapper {
      flex-wrap: wrap;
      margin-left: 0.5em;
      .counter_box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 3em;
        margin-left: 1em;
      }
    }
  }

  .home_sec3 {
    .text_container {
      margin-bottom: 0;
      .desc {
        margin-top: 1.5em;
      }
      .chemical_img {
        margin-bottom: 3em;
      }
      .btn_wrapper {
        display: flex;
        justify-content: center;
        .common_btn {
          border: 1px solid $color-BE8E34 !important;
          color: $color-000000 !important;
        }
      }
    }
  }

  .home_sec4 {
    .my_container {
      padding: 0 15px !important;
    }
    .subheading_box {
      text-align: left;
      .breadcrumb_text {
        @include textStyles(1.4em, 1.7em, 0.14px);
      }
      .heading {
        br {
          display: none;
        }
      }
    }
    .box_container {
      .whyswiper {
        .content_box {
          margin: 1em 0 5em;
          &:hover {
            -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            box-shadow: 0px 1px 6px 0.6px $color-00000029;
            background-color: $color-ffffff;
            border-bottom: 0.45em solid $color-C6A14A;
          }
          .box_icon {
            max-width: 3.1em;
          }
          .heading {
            @include textStyles(2em, 1.7em, 0.2px);
          }
          .desc {
            padding: 0 1em;
          }
        }
      }
    }
  }

  .home_sec5 {
    margin-bottom: 5em;
    .text_container {
      .heading {
        br {
          display: none;
        }
      }
    }
    .products_swiper {
      .productsswiper {
        .slider_box {
          display: flex;
          flex-direction: column;
          -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
          -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
          box-shadow: 0px 1px 6px 0.6px $color-00000029;
          z-index: 2;
          margin: 3.75em 0.2em 4em;
          padding: 2em;
          text-align: center;
          &:hover {
            -webkit-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            -moz-box-shadow: 0px 1px 6px 0.6px $color-00000029;
            box-shadow: 0px 1px 6px 0.6px $color-00000029;
            background-color: $color-ffffff;
          }
          .heading {
            padding-left: 0;
            max-width: 40%;
          }
          .border_line {
            width: 100%;
            max-width: 80%;
            min-height: 0.1em;
            max-height: 0.1em;
            // transform: rotateZ(90deg);
          }
          .desc_wrapper {
            align-items: center;
          }
        }
        .swiper-pagination {
          display: block;
          .swiper-pagination-bullet-active {
            background-color: $color-202020;
          }
        }
      }
      .navigation_arrows_wrapper {
        display: none;
      }
    }
  }

  .home_sec6 {
    .testimonials_swiper {
      .testimonialsswiper {
        .testimonial_box {
          .desc {
            padding: 0 1em;
          }
          .testimonial_name {
            @include textStyles(1.6em, 1.7em, 0px);
          }
        }
      }
      .navigations {
        .navigation_left {
          font-size: 1.2em;
          margin: 0 0 1em 1em;
        }
        .navigation_right {
          font-size: 1.2em;
          margin: 0 1em 1em 0;
        }
      }
    }
  }

  .home_sec7 {
    .desc_brand_row {
      .text_container {
        .heading {
          br {
            display: none;
          }
        }
      }
      .brand_swiper {
        .brand_img1 {
          max-width: 13em;
        }
        .brand_img2 {
          max-width: 14.4em;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .home_sec1 {
    .banner_sec {
      .banner_text {
        font-size: 2.5em;
      }
      .btn_wrapper {
        left: 27.5%;
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
